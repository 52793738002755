import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

//Components
import SEO from "../components/seo"
import Header from "../components/navigation/header"
import Title from "../components/title"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Product from "../components/products/product"

// Images
import certifications from "../images/certifications.svg"

import img1 from "../images/suppliers/1.png"
import img2 from "../images/suppliers/2.png"
import img3 from "../images/suppliers/3.png"
import img4 from "../images/suppliers/4.png"
import img5 from "../images/suppliers/5.png"
import img6 from "../images/suppliers/6.png"
import img7 from "../images/suppliers/7.png"
import img8 from "../images/suppliers/8.png"
import img9 from "../images/suppliers/9.png"
import img10 from "../images/suppliers/10.png"

// Styles
import styles from "../styles/pages/products.module.scss"
import { Container, Row, Col } from "react-bootstrap"

export default function Products() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProductNew {
        edges {
          node {
            name
            shortDescription {
              shortDescription
            }
            images {
              fixed(quality: 100, height: 300) {
                ...GatsbyContentfulFixed
              }
            }
            productBrochure {
              file {
                url
              }
            }
            slug
            priority
          }
        }
      }
    }
  `)

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 10,
    },
    largeDesktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 1024, min: 960 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 960, min: 640 },
      items: 4,
    },
    largeMobile: {
      breakpoint: { max: 640, min: 480 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  }

  return (
    <div>
      <Header />
      <SEO
        title="Products"
        article={false}
        pathname="/products"
        description="Have a look at the high efficiency solar panels and inverters we use for our solar systems in Sri Lanka."
      />
      <section>
        <Layout>
          <Title
            text="Featured Products"
            subtitle="Excellent quality and durability, backed with a generation guarantee"
          />
          <Container>
            <Row>
              {data.allContentfulProductNew.edges
                .sort((a, b) => a.node.priority - b.node.priority)
                .map((edge, idx) => (
                  <Col xl={4} md={6}>
                    <Product
                      key={idx}
                      title={edge.node.name}
                      description={edge.node.shortDescription.shortDescription}
                      image={edge.node.images[0]}
                      productBrochure={
                        edge.node.productBrochure &&
                        edge.node.productBrochure.file.url
                      }
                      slug={edge.node.slug}
                    />
                  </Col>
                ))}
            </Row>
          </Container>
          <img
            src={certifications}
            alt="Product Certifications"
            className={styles.certs}
          />
          {/* SUPPLIER IMAGES */}
          <Carousel responsive={responsive} className={styles.supplierImages}>
            {[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10].map(
              (img, idx) => (
                <div key={idx} className={styles.supplierImageItem}>
                  <img src={img} alt={`Supplier ${idx + 1}`} />
                </div>
              )
            )}
          </Carousel>
        </Layout>
      </section>
      <Footer />
    </div>
  )
}
