import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { FaFilePdf } from "react-icons/fa"
import { Card } from "react-bootstrap"

// Styles
import styles from "../../styles/components/products/product.module.scss"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"

export default function Product({
  image,
  title,
  description,
  productBrochure,
  slug,
}) {
  return (
    <Link to={`/products/${slug}`}>
      <Card
        className={`bg-light.bg-gradient p-4 shadow p-3 mb-5 bg-white rounded ${styles.card}`}
      >
        <div class="">
          <div className={styles.imgWrapper}>
            <Img
              fixed={image.fixed}
              alt="title"
              className={styles.image}
              objectFit="contain"
            />
          </div>
          <h5 className={` ${styles.title} text-start pb-3`}>{title}</h5>
          {/* {description && <p class="card-text">{ String(.description).substring(0,95)}</p>} */}
          <p className={styles.description}>
            {description.split(/\r?\n/).map(description => (
              <div>{description}</div>
            ))}
          </p>
          {productBrochure && (
            <a href={productBrochure}>
              <button className={styles.link}>
                <FaFilePdf />
              </button>
            </a>
          )}
        </div>
      </Card>
    </Link>
  )
}
